.hover-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.hover-container > div{
    overflow: hidden;
}

.hover-card {
    position: relative;
}

.span {
    visibility: hidden;
}

.hover-card .span {
    position: absolute;
    /* top: -4vw;
    left: 0vw; */
    color: black;
    text-align: center;
    opacity: 0.9;
    font-size: 1rem;
    font-family: 'Ubuntu', sans-serif;
}

.hover-card:hover .span{
    top: 1vw;
    opacity: 0.9;
    visibility: visible;
    transition: all 0.5s ease-in-out;
}

.hover-card .img-opacity{
    opacity: 1;
}

.hover-card:hover .img-opacity {
    opacity: 0.1;
}