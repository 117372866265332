.custom-grid {
    display: flex;
    flex-wrap: wrap;
}

.custom-grid-item {
    flex-grow: 1;
    min-width: 25%;
}

.some-color {
    color: blue;
}