@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&display=swap');


.fontchange {
  font-family: 'Inter', sans-serif;
}

.bolded {
  font-family: 'DM Sans', sans-serif;
}


.margin_top{
  margin-top: 20px;
}

.unselectable-text{
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.my-toggle-btn-wrapper{
  text-align: center;
}
.my-toggle-btn{
  cursor: pointer;
  display: inline-block;
}
/* .my-toggle-btn input[type=checkbox]{
  display: none;
} */
.my-toggle-btn input[id=checkbox2]{
  display: none;
}
.my-toggle-btn input[id=checkbox1]{
  display: none;
}
.my-toggle-btn input[id=checkbox3]{
  display: none;
}
.my-toggle-btn label{
  background: rgba(218,219,219,255);
  color: #000;
  width: 120px;
  height: 34px;
  font-size: 22px;
  display: inline-block;
  border-radius: 20px;
  position: relative;
  text-align: center;
  transition: all .4s ease;
  cursor: pointer;
}
.my-toggle-btn label:before{
  border-radius: 20px;
  content: '';
  position: absolute;
  height: 30px;
  width: 25px;
  background: #444;
  left: 2px;
  top: 1px;
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
}
.my-toggle-btn input[type=checkbox]:checked + label:before{
  left: 92px;
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
}
.my-toggle-btn input[type=checkbox]:checked + label span.off{
  display: none;
}
.my-toggle-btn input[type=checkbox]:checked + label span.on{
  display: inline;
}
.my-toggle-btn input[type=checkbox] + label span.on{
  display: none;
}
.my-toggle-btn input[type=checkbox] + label span.off{
  display: inline;
}

.my-toggle-btn input[type=checkbox]:checked + label{
  background: rgba(147,211,68,255);
}
.progress-bar-css1{
  height:10px;
}
.progress-bar-css2{
  width:50%;
  background-color: yellow;
}

.checkbox {
  position: absolute;
  top: 0vh;
  left: 6vw;
  opacity: 0.5;
  cursor: pointer;
  height: 80px;
  width: 80px;
  z-index: 2;
  border: none;
  outline: 0;
}

@media screen and (max-width:450px){
  .checkbox {
    top: 0vh;
    left: 10.5vw;
    opacity: 0.5;
    cursor: pointer;
    height: 80px;
    width: 80px;
    z-index: 2;
    border: none;
    outline: 0;
  }
}

.containerz {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.containerz input {
  display: none;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 4vw;
  height: 22px;
  width: 22px;
  /* border: 1px solid black; */
  border-radius: 50%;
  background-color: #ecf0f4;
}
.containerz:hover input ~ .checkmark {
  background-color: #ccc;
}

.containerz input:checked ~ .checkmark {
  background-color: #2196F3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.containerz input:checked ~ .checkmark:after {
  display: block;
}
.containerz .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

input:checked ~ .checkmark:after {
  display: block;
} 


/* .checkmark:after {
  content: "✔";
  background-color: #2196F3;
  border: 1px solid black;
  position: absolute;
  display: none;
} */


/* When the checkbox is checked, add a blue background */
/* input:checked ~ .checkmark {
  background-color: #2196F3;
}

input:disabled ~ .checkmark {
  border: 0;
  background-color: #eee;
}

input:disabled ~ .checkmark:hover {
  cursor: not-allowed
}

/* Show the checkmark when checked */




.containers {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
}

/* Hide the browser's default checkbox */
.containers input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmarks {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 2px solid lightgrey;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.containers:hover input ~ .checkmarks {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containers input:checked ~ .checkmarks {
  border: 3px solid #2196F3;
  background-color: #afd9fa;
}

/* Show the checkmark when checked */
.containers input:checked ~ .checkmarks:after {
  display: block;
}

/* Style the checkmark/indicator */
.containers .checkmarks:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.reducesize{
  width: 60%;
  margin-top: 10px;
}

.paddinginter{
  padding-left: 10px;
  padding-right: 10px;

}

.upper_row{
  height: fit-content;
  padding: 0px;
}

.margin-fix{
  margin-left: 10px;
  margin-right: 15px;
}

.blue_background{
  background: #2196F3;
}

.center{
  align-items: center;
}
